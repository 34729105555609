<template>
  <div class="submitBox">
    <div class="headerBox">
      <div class="left">
        <img src="../../assets/web2x_zuoye.png" alt="" />
        作业
      </div>
      <div class="right">
        <p>{{ info.title }}</p>
        <p>
          <span
            >提交起止时间： {{ info.release_time }}~{{ info.close_time }}</span
          ><span> {{ info.total_points }}分</span
          ><span>
            {{
              info.is_overtime == 0 ? "允许超时提交" : "不允许超时提交"
            }}</span
          >
        </p>
      </div>
    </div>
    <div class="centerBox">
      <p v-html="info.content"></p>
      <el-button
        type="primary"
        @click="yesBtn"
        :loading="btnLoading"
        style="transform: scale(1.3)"
        v-if="this.isEmpty || this.studentjob.status == 0"
        >确认提交</el-button
      >
    </div>
    <div class="contentBoxx">
      <!-- status 1一批阅 2未批阅 0未提交 -->
      <div class="pyhBox" v-if="[1, 2].includes(this.studentjob.status)">
        <p style="font-size: 20px">老师评语</p>
        <div class="pyContentBox">
          <p class="pyContent">
            {{ studentjob.comment || "暂无评语" }}
          </p>
          <div class="pyContentRight">
            <p class="title">成绩</p>
            <p class="fenshu">
              <span>{{
                !this.studentjob.score ? "分数未公布" : this.studentjob.score
              }}</span
              >/ <span>{{ info.total_points }}</span>
            </p>
          </div>
        </div>
      </div>
      <div
        class="pyhBox"
        v-if="this.isRepulse.length && this.studentjob.status == 0"
      >
        <p style="font-size: 20px">
          打回原因
          <span class="repulseHistory" @click="repulseHistoryBtn"
            ><i class="el-icon-time"></i>提交历史</span
          >
        </p>
        <div class="pyContentBox">
          <p class="pyContent">
            {{ this.isRepulse[0].repulse_content || "未填写打回原因" }}
          </p>
          <div class="pyContentRight">
            <p class="title">作业状态</p>
            <p class="fenshu" style="color: #ff6103">被打回</p>
          </div>
        </div>
      </div>
      <div
        class="contentBox"
        :class="this.studentjob.status || 'notHaveStatus'"
      >
        <p style="font-size: 20px">作业附件</p>
        <div
          class="upLoadBox"
          v-if="this.isEmpty || this.studentjob.status == 0"
        >
          <el-upload
            class="upload-demo"
            drag
            action=""
            :on-change="handleChange"
            :on-remove="handleRemove"
            :file-list="fileList"
            :auto-upload="false"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              <em> 点击上传添加作业文件</em>
              <p style="margin-top: 10px">
                支持各类文档、图片、代码、压缩包格式
              </p>
            </div>
          </el-upload>
        </div>
        <div class="fileUl" v-if="!this.isEmpty && this.studentjob.status != 0">
          <ul>
            <li v-for="item in fileList" :key="item.id">
              <span>{{ item.name }}</span>
              <a :href="item.url">下载</a>
            </li>
          </ul>
        </div>
        <p style="font-size: 20px">
          作业留言
          <span style="font-size: 16px; color: #74787c; margin-left: 7px"
            >选填</span
          >
        </p>
        <el-input
          style="margin-top: 10px; font-size: 20px"
          type="textarea"
          :rows="4"
          placeholder="作业以附件形式提交，留言仅作备注使用哦！"
          v-model="textarea"
          v-if="this.isEmpty || this.studentjob.status == 0"
        >
        </el-input>
        <p style="margin-top: 10px" v-else>
          {{ textarea ? textarea : "暂无留言" }}
        </p>
      </div>
    </div>
    <progressDialog ref="progressDialog" />
    <repulseHistoryDialog ref="repulseHistoryDialog" />
  </div>
</template>
<script>
import axios from "axios";
import md5 from "js-md5";
import { JobDetails, jobSubmit, uploadeFile, jobSubmits } from "@/api/order.js";
import progressDialog from "@/components/progressDialog.vue";
import repulseHistoryDialog from "@/components/repulseHistoryDialog.vue";

// import { uploadOSS } from "@/utils/oss";
import { client, headers, getFileNameUUID, rules } from "@/utils/oss.js";
export default {
  data() {
    return {
      fileList: [],
      textarea: "",
      info: {},
      idList: [],
      btnLoading: false,
      studentjob: {},
      closePage: false,
      fileName: "",
      delId: [],
      fileInfo: {},
      isEmpty: false,
      isRepulse: [],
    };
  },
  created() {
    this.getList();
  },
  watch: {
    // fileList: {
    //   handler(val) {
    //     console.log("watch");
    //     console.log(val);
    //   },
    // },
  },
  components: { progressDialog, repulseHistoryDialog },
  methods: {
    yesBtn() {
      this.$confirm("确认提交作业？")
        .then((_) => {
          let params = {
            content: this.textarea,
            job_id: this.$route.query.id,
            file: this.idList,
            delId: this.delId,
            fileList: this.fileList,
            grade_id: sessionStorage.getItem("gradeID"),
          };
          this.btnLoading = true;
          jobSubmits({ ...params })
            .then((res) => {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.btnLoading = false;
              setTimeout(() => {
                location.reload();
              }, 1000);
            })
            .catch((_) => {
              this.btnLoading = false;
            });
        })
        .catch((_) => {});
    },
    repulseHistoryBtn() {
      this.$refs.repulseHistoryDialog.show(this.isRepulse);
    },
    getList() {
      JobDetails({ id: this.$route.query.id }).then((res) => {
        this.info = res.data;
        if (res.studentjob) {
          this.textarea = res.studentjob.content;
          this.studentjob = res.studentjob; // status 1一批阅 2未批阅 0未提交
          this.isRepulse = res.studentjob.repulse;
          if (res.studentjob.file.length) {
            this.fileList = res.studentjob.file.map((item) => {
              return {
                name: item.name,
                url: item.path,
                id: item.id,
              };
            });
            res.studentjob.file.forEach((item) => {
              this.idList.push(item.id);
            });
          }
        }
        const obj = this.studentjob;
        const isEmpty = Object.keys(obj).length === 0;
        this.isEmpty = isEmpty;
      });
    },
    handleRemove(file) {
      let index = this.fileList.findIndex((item) => {
        return item.name == file.name;
      });
      this.delId.push(this.fileList[index].id);
      this.fileList.splice(index, 1);
      let index2 = this.fileList.findIndex((item) => {
        return item.id == file.id;
      });
      this.idList.splice(index2, 1);
    },
    cancelApi(file) {
      console.log("CLOSEFILE");
      console.log(file);
      let index = this.fileList.findIndex((item) => {
        return item.uuid == file.uuid;
      });
      this.fileList.splice(index, 1);
      this.closePage = true;
    },
    handleChange(file) {
      console.log(file);
      // this.fileList.push(file);
      let nunmber = new Date().getTime();
      var fileName = "学生作业/" + nunmber + file.name;
      console.log(fileName);
      let that = this;
      client()
        .multipartUpload(
          fileName,
          file.raw,
          // {
          //   headers,
          //   rules,
          // },
          {
            progress: function (percentage, cpt) {
              if (!that.closePage) {
                that.$refs.progressDialog.show(
                  Math.floor(percentage * 100),
                  file,
                  nunmber
                );
              } else {
                that.closePage = false;
                client.cancel();
              }
            },
          }
        )
        .then((res) => {
          // console.log(res);
          // this.fileList.push({ url: res.name, name: res.name });
          // console.log(this.fileList);
        });
      return;
      // this.btnLoading = true;
      // let data = {
      //   file: files.raw,
      //   uid: files.uid,
      // };
      // let a = JSON.stringify(data) + "rd_ktm_app";
      // data.signature_data = JSON.stringify(data);
      // data.signature = md5(a);
      // let formData = new FormData();
      // let arr = Object.keys(data);
      // arr.forEach((item) => {
      //   if (item == "file") {
      //     formData.append(item, data[item]);
      //   } else {
      //     formData.append(item, data[item]);
      //   }
      // });
      // let _this = this;
      // _this.$message("正在上传，请稍后~");
      // axios
      //   .post(
      //     "http://api.ruandy.com/ketang_v1.0/api/admin/uploadeFile",
      //     formData,
      //     { "Content-type": "multipart/form-data" }
      //   )
      //   .then(function (response) {
      //     _this.btnLoading = false;
      //     _this.idList.push(response.data.data.id);
      //   })
      //   .catch(function (error) {
      //     _this.$message(error);
      //     _this.btnLoading = false;
      //   });
      // // uploadeFile({ file: files.raw, uid: files.uid }).then((res) => {
      // //   this.idList.push(res.data.id);
      // //   this.btnLoading = false;
      // // });
    },
  },
};
</script>
<style lang="scss" scoped>
.submitBox {
  width: 100%;
}
.headerBox {
  width: 100%;
  border-radius: 4px;
  border-bottom: 1px solid #dadce0;
  padding: 10px 16px 20px 16px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    img {
      margin-bottom: 10px;
    }
  }
  .right {
    p {
      &:nth-child(1) {
        font-size: 20px;
        font-weight: 500;
        max-width: 670px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
      }
      &:nth-child(2) {
        font-size: 16px;
        margin: 10px 0;
        span {
          display: inline-block;
          padding: 4px;
          box-sizing: border-box;
          border-radius: 4px;
          margin-right: 8px;
          &:nth-child(1),
          &:nth-child(2) {
            background-color: #ecf3fe;
            border-color: #d9e7fd;
            color: #4285f4;
          }
          &:nth-child(3),
          &:nth-child(4) {
            background: #f8f9fa;
            border: 1px solid #f8f9fa;
            color: #909399;
          }
        }
      }
    }
  }
}
.centerBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  box-sizing: border-box;
  p {
    font-size: 26px;
    font-weight: 500;
  }
}
.contentBoxx {
  padding-bottom: 100px;
  box-sizing: border-box;
}
.pyhBox {
  padding: 24px;
  box-sizing: border-box;
  background: #f8f9fa;
  border-radius: 8px 8px 0 0;
  border: 1px solid #dadce0;
  border-bottom: none;
  .repulseHistory {
    color: #528ff5;
    font-size: 16px;
    cursor: pointer;
  }
  .pyContentBox {
    display: flex;
    justify-content: space-between;
  }
  .pyContent {
    padding: 20px;
    box-sizing: border-box;
    background: #fff;
    margin: 20px 0;
    width: 76%;
  }
  .pyContentRight {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    .title {
      font-size: 20px;
      margin-bottom: 4px;
    }
    .fenshu {
      font-size: 30px;
      :nth-child(1) {
        color: #ff6103;
      }
    }
  }
}
.contentBox {
  width: 100%;
  border: 1px solid #dadce0;
  border-radius: 0 0 8px 8px;
  border-top: none;
  padding: 24px;
  box-sizing: border-box;
  .upLoadBox {
    padding: 20px 0;
    box-sizing: border-box;
  }
}
.fileUl {
  margin: 10px 0 20px 0;
  li {
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ebecee;
    border-radius: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    a {
      text-decoration: none;
      color: #488df5;
    }
  }
}
.notHaveStatus {
  border-top: 1px solid #dadce0;
  border-radius: 4px;
}
::v-deep .el-upload,
::v-deep .el-upload-dragger {
  width: 100%;
}
::v-deep .el-upload-dragger {
  padding: 20px 0 0 0;
  box-sizing: border-box;
  height: 260px;
  background: #f8f9fa;
  border: none;
}
::v-deep .el-upload-list__item .el-icon-upload-success {
  display: none;
}
::v-deep .el-upload-list__item {
  padding: 20px 10px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #dadce0;
  font-size: 20px;
  margin-bottom: 20px;
}
::v-deep .el-upload-list__item .el-icon-close {
  position: absolute;
  top: 40%;
  right: 20px;
}
</style>
