<template>
  <div>
    <el-dialog
      :title="`作业提交历史（${isRepulse.length}次）`"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
    >
      <el-timeline>
        <el-timeline-item
          v-for="item in isRepulse"
          :key="item.repulse_id"
          :timestamp="item.create_time"
          placement="top"
        >
          <el-card>
            <h4>{{ item.repulse_content || "未填写打回原因" }}</h4>
          </el-card>
        </el-timeline-item>
      </el-timeline>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      isRepulse: [],
    };
  },
  methods: {
    show(isRepulse) {
      this.isRepulse = isRepulse;
      this.dialogVisible = true;
    },
    handleClose() {
      this.isRepulse = [];
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-timeline-item__timestamp {
  color: #498af4;
  font-size: 16px;
  position: relative;
  &::after {
    position: absolute;
    content: "已打回";
    color: #ff6000;
  }
}
::v-deep .el-card {
  box-shadow: none;
  border-radius: 10px;
}
</style>
